import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component( {
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
} )
export class AboutComponent implements OnInit {

    items = [];

    constructor( private contentService: ContentService ) { }

    ngOnInit() {
        this.getItems();
    }

    getItems() {
        this.contentService.getHistory().subscribe( ( resp: any ) => {
            console.log( resp );
            this.items = resp;
        } );
    }

}
