import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component( {
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
} )
export class ServicesComponent implements OnInit {

    services = [];
    service: any;
	service1: any;
	service2: any;
	service3: any;
	service4: any;

    constructor( private contentService: ContentService ) { }

    ngOnInit() {
        this.getServices();
    }

    getServices() {
        this.contentService.getServices().subscribe( ( res: any ) => {
            console.log( res );
            this.services = res;
            if ( this.services.length ) {
                this.service = this.services[ 0 ];
				this.service1 = this.services[ 0 ];
				this.service2 = this.services[ 1 ];
				this.service3 = this.services[ 2 ];
				this.service4 = this.services[ 3 ];
            }
        } );
    }

}
