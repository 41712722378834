import { Component, OnInit } from '@angular/core';

@Component( {
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
} )
export class HeaderComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    onClickHref() {
        document.getElementById( 'mySidenav' ).style.width = '0';
    }

    openNav() {
        document.getElementById( 'mySidenav' ).style.width = '100%';
        document.getElementById( 'body' ).style.overflow = 'auto';
    }

    closeNav() {
        document.getElementById( 'mySidenav' ).style.width = '0';
        document.getElementById( 'body' ).style.overflow = 'block';
    }

}
