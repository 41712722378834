import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component( {
    selector: 'app-top',
    templateUrl: './top.component.html',
    styleUrls: ['./top.component.scss']
} )
export class TopComponent implements OnInit {

    banners = [];
    bannerActive: any;
    i: number;
    interval: any;

    constructor( private contentService: ContentService ) { }

    ngOnInit() {
        this.getBanners();
    }

    getBanners() {
        this.contentService.getBanners().subscribe( ( res: any ) => {
            console.log( res );
            this.banners = res;

            if ( window.innerWidth >= 768 ) {
                this.setBackground( 0 );
            } else {
                if ( this.banners.length ) {
                    this.bannerActive = this.banners[ 0 ];
                }
            }
        } );

    }

    setBackground( i: number ) {
        this.i = i;
        this.bannerActive = this.banners[ i ];
        if ( this.interval ) {
            clearInterval( this.interval );
        }

        this.interval = setInterval( () => {
            this.i++;
            if ( this.banners[ this.i ] ) {
                this.bannerActive = this.banners[ this.i ];
            } else {
                this.bannerActive = this.banners[ 0 ];
                this.i = 0;
            }
        }, 5000 );
    }

}
