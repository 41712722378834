import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component( {
    selector: 'app-testimonies',
    templateUrl: './testimonies.component.html',
    styleUrls: ['./testimonies.component.scss']
} )
export class TestimoniesComponent implements OnInit {

    testimonies = [];

    constructor( private contentService: ContentService ) { }

    ngOnInit() {
        this.getTestimonies();
    }

    private getTestimonies() {
        this.contentService.getTestimonies().subscribe( ( res: any ) => {
            console.log( res );
            this.testimonies = res;
        } );
    }
}
