import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermConditionComponent } from './pages/term-conditions/term-conditions.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
    {
        path: 'terminos-y-condiciones',
        component: TermConditionComponent,
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/'
    }
];

@NgModule( {
    imports: [RouterModule.forRoot( routes, { useHash: false } )],
    exports: [RouterModule]
} )
export class AppRoutingModule {}
