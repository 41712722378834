import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component( {
    selector: 'app-root',
    templateUrl: './term-conditions.component.html',
    styleUrls: ['./term-conditions.component.scss']
} )
export class TermConditionComponent implements OnInit {
    title = 'icn-frontend';
    contentDescription = '';
    service: any;

    constructor(private contentService: ContentService) { }

    ngOnInit() {
        this.getTermConditions();
    }

    getTermConditions() {
        this.contentService.getTermConditions().subscribe((res: any) => {
            document.getElementById('termCondition').innerHTML = res.description;
        });
    }
}
