import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentService } from '../../services/content.service';
import { finalize } from 'rxjs/operators';

@Component( {
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
} )
export class ContactFormComponent implements OnInit {

    form: FormGroup;
    isLoading: boolean;
    success: boolean;
    error: boolean;
    dataConfiguration: {conact_number: any} = {conact_number: null};

    constructor( private fb: FormBuilder,
                 private contentService: ContentService ) { }

    ngOnInit() {
        this.initForm();
        this.loadInformationConfiguration();
    }

    loadInformationConfiguration(){
        this.contentService.getConfiguration().subscribe((response: any)=>{
            this.dataConfiguration.conact_number = response.contact_text;
        })
    }
    initForm() {
        this.form = this.fb.group( {
            name: [null, Validators.required],
            email: [null, Validators.required],
            phone: [null, Validators.required],
            message: [null, Validators.required]
        } );
    }

    sendMessage() {
        this.success = false;
        this.error = false;
        if ( this.form.valid ) {
            this.isLoading = true;
            this.contentService.sendMessage( { ...this.form.value } )
            .pipe( finalize( () => this.isLoading = false ) )
            .subscribe( res => {
                console.log( res );
                this.success = true;
                this.form.reset();
            }, error => this.error = true );
        }
    }

}
