import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { AppComponent } from './app.component';
import { TermConditionComponent } from './pages/term-conditions/term-conditions.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { TopComponent } from './components/top/top.component';
import { AboutComponent } from './components/about/about.component';
import { ContentService } from './services/content.service';
import { HttpClientModule } from '@angular/common/http';
import { ServicesComponent } from './components/services/services.component';
import { TestimoniesComponent } from './components/testimonies/testimonies.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyNumbersDirective } from './components/directives/only-numbers.directive';

@NgModule( {
    declarations: [
        AppComponent,
        HeaderComponent,
        TopComponent,
        AboutComponent,
        ServicesComponent,
        TestimoniesComponent,
        ContactFormComponent,
        OnlyNumbersDirective,
        TermConditionComponent,
        HomeComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TabsModule.forRoot(),
        CarouselModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [OnlyNumbersDirective],
    providers: [ContentService],
    bootstrap: [AppComponent]
} )
export class AppModule {}
