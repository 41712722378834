import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import AOS from 'aos';

@Component( {
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
} )
export class AppComponent implements OnInit {
    title = 'icn-frontend';

    player: AnimationPlayer;

    constructor( private router: Router,
                 private animationBuilder: AnimationBuilder ) {}

    ngOnInit() {
        AOS.init();
        this.router.events
        .pipe(
            filter( event => event instanceof NavigationEnd ),
            take( 1 ) ).subscribe( () => this.removeClass(), () => this.removeClass() );
    }

    removeClass() {
        const spinner = window.document.getElementById( 'spinner-wrapper' );
        this.player = this.animationBuilder
        .build( [
            style( { opacity: '1' } ),
            animate(
                '400ms ease',
                style( {
                    opacity: '0',
                    zIndex: '-10'
                } )
            )
        ] )
        .create( spinner );
        this.player.play();
    }
}
