import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable( {
    providedIn: 'root'
} )
export class ContentService {

    constructor( private http: HttpClient ) { }

    getHistory() {
        const url = `${environment.api}/histories`;
        return this.http.get( url );
    }

    getBanners() {
        const url = `${environment.api}/banners`;
        return this.http.get( url );
    }

    getServices() {
        const url = `${environment.api}/businesses`;
        return this.http.get( url );
    }

    getTestimonies() {
        const url = `${environment.api}/testimonies`;
        return this.http.get( url );
    }
    getTermConditions(){
        const url = `${environment.api}/get-term-condition`;
        return this.http.get( url );
    }

    sendMessage( data: any ) {
        const url = `${environment.api}/contacts`;
        return this.http.post( url, data );
    }
    getConfiguration() {
        const url = `${environment.api}/configuration`;
        return this.http.get( url );
    }
}
